import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  FormSizePartial,
  HasErrorPartial,
  HelpTextPartial,
  IsDisabledPartial,
  IsReadOnlyPartial,
  IsRequiredPartial,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Input = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Input;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/input.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Input"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/forms-inputs--docs"
      />
      <CodeSnippet
        scope={{ React: React, Input: Input }}
        code={snippet}
        platform="react"
        gitHubLink="forms/input"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="input" />

          <EnvironmentPartial />

          <PropListItem name="type" types={['string']}>
            <Text>
              The type of input. This is a{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Learn/Forms/HTML5_input_types">
                built-in HTML5 property.
              </Link>
            </Text>
            <List type="unordered">
              <li>
                <code>date</code>
              </li>
              <li>
                <code>email</code>
              </li>
              <li>
                <code>number</code>
              </li>
              <li>
                <code>password</code>
              </li>
              <li>
                <code>tel</code>
              </li>
              <li>
                <code>text</code> (default)
              </li>
              <li>
                <code>time</code>
              </li>
              <li>
                <code>url</code>
              </li>
            </List>
          </PropListItem>

          <FormSizePartial componentName="input" />

          <HasErrorPartial componentName="input" />

          <HelpTextPartial componentName="input" />

          <IsDisabledPartial componentName="input" />

          <IsReadOnlyPartial componentName="input" />

          <IsRequiredPartial componentName="input" />

          <PropListItem name="label" types={['string']}>
            <Text>The text that appears above the input.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial
            componentName="input"
            passedDown={['label', 'help-text']}
          />

          <PropListItem name="...props" types={[]}>
            <Text>
              You can pass in any of the other props from an HTML{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input">
                input
              </Link>{' '}
              element.
            </Text>
          </PropListItem>
        </PropList>
      </Section>

      <Section title="Notes">
        <List type="unordered">
          <li>
            If you pass in a <code>value</code> prop, be sure you also pass in
            an <code>onChange</code> prop so the{' '}
            <Link href="https://reactjs.org/docs/forms.html#controlled-components">
              component will update
            </Link>{' '}
            when the user types.
          </li>
          <li>
            Since the <code>name</code> attribute is passed to the{' '}
            <code>input</code>
            ’s <code>id</code>, make sure you provide a unique name.
          </li>
          <li>
            To show an error message, simply pass in <code>hasError</code> and
            put the error text in the <code>helpText</code> attribute.
          </li>
        </List>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Forms / Input"
      />
    </PlatformTab>
  );
};

export default WebTab;
